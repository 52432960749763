<template>
  <section class="login__box">
    <div class="login__bgs">
      <img src="@/assets/img/loginbg.jpg" alt=""/>
    </div>
    <div class="login__info">
      <h3 class="login__title">登录</h3>
      <div class="login__text">
        <p>欢迎使用安吉集团招聘系统，请输入手机号码和密码登录。</p>
      </div>
      <div class="login__form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" @keyup.enter.native="submitForm('ruleForm')"  class="demo-ruleForm">
          <el-form-item prop="telphone">
            <span class="iconfont">&#xe78f;</span>
            <el-input placeholder="手机号" v-model="ruleForm.telphone"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <span class="iconfont">&#xe790;</span>
            <el-input type="password" placeholder="密码" v-model="ruleForm.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login__btn" type="primary" @click="submitForm('ruleForm')">立即登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="login__down">
        <router-link to="/forget">忘记密码</router-link>
        <router-link to="/register">立即注册</router-link>
      </div>
    </div>
    <div class="login__copy">
      <p>四川安吉物流集团有限公司Copyright2023  蜀ICP备2023013433号-1    公安局备案号5115000334  <a href="#">技术支持</a>：<a href="#">今网科技</a></p>
    </div>
  </section>
</template>

<script>
import { useMyStore } from '../store';
export default {
data(){
  var validatePassword = (rule, value, callback) => {
    if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.])[\da-zA-Z~!@#$%^&*.]{8,}$/.test(value)) {
      callback(new Error('密码必须包含至少一个字母和一个数字'))
    } else {
      callback()
    }
  };
  return {
    menuNumber: 5,
    contactData: [],
    numble: 0,
    ruleForm: {
        telphone: '',
        password:'',
    },
    rules: {  
        telphone: [
          { required: true, message: '请输入电话', trigger: ['blur', 'change'] },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['change','blur']},
          { min: 8, max: 20, message: '密码长度在8到20个字符', trigger: ['blur', 'change'] },
          { validator: validatePassword, message: '密码必须包含至少一个字母和一个数字和一个特殊符号!', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {data: res} = await this.$http.post("login", this.ruleForm);
          if(res.status == 0){
            this.$message.error(res.info);
          }else{
            localStorage.setItem('token', res.data.token); 
            useMyStore().setToken(res.data.token);
            
            this.$message({
              message: res.info,
              type: 'success'
            });
            this.$router.push('/concept/socialrecruit')
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style>

</style>